body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
#root{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 15px;
	background-color: rgb(122,153,217);
}
::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgb(175, 202, 255);
	border-radius: 10px;
} 

*::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.34, rgb(122,153,217)),color-stop(0.62, rgb(73,125,189)),color-stop(0.76, rgb(28,58,148)));
height: 25px;
}

*::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background:  -webkit-gradient(linear,left bottom,left top,color-stop(0.34, rgb(154, 136, 255)),color-stop(0.62, rgb(112, 98, 194)),color-stop(0.76, rgb(70, 59, 134)));
}

.simplebar-scrollbar::before {
  background-color: rgb(122, 153, 217);
}

.simplebar-scrollbar.simplebar-visible::before {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.34, rgb(122, 153, 217)), color-stop(0.62, rgb(73, 125, 189)), color-stop(0.76, rgb(28, 58, 148)));
}

.simplebar-scrollbar.simplebar-visible:hover::before {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0.34, rgb(154, 136, 255)), color-stop(0.62, rgb(112, 98, 194)), color-stop(0.76, rgb(70, 59, 134)));
}
