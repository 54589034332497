.buttons {
    transition: all 0.5s ease-in-out;
    box-shadow: inset 0 0 0 0.09px white;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 6px;
    font-weight: 300;
    background: #015d81;
    color: white;
    padding: 1px 8px;
    border-radius: 20px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons:hover {
    box-shadow: inset 250px 0 0 0 white;
    color: black;
}