
.side-nav {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.603) 50%,
    rgba(2, 54, 70, 0.788) 50%
  );
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: none;
}

.side-nav.open {
  display: block;
  background-color: rgba(255, 255, 255, 0.651);
  animation-name: circleAnimation;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

/* Icon */
.burger-menu-class {
  position: fixed;
  z-index: 3;
  right: 0;
  margin-right: 1%;
}

/* Menu item*/
.navbar-list-desktop{
  list-style: none;
  margin-left: 18%;
  margin-top: 5%;

}
.navbar-list-desktop li{
  font-size: 30px;
  padding-bottom: 155px;
 }
.navbar-list-desktop li a{ 
  color: #5eb9dd;
 }

.spinneri {
  position: absolute;
  right: 21%;
  top: 38%;
}

.spinneri__image {
  width: 300px;
  height: 300px;
  background-size: contain;
  background-image: url("/public/assets/images/CCLogo.png");
}


@media screen and (max-width: 900px) {
  .side-nav,
  .side-nav.open,
  .burger-menu-class {
    display: none;
  }
}


@media screen and (max-width: 700px) and (orientation: landscape) {
  .side-nav,
  .side-nav.open,
  .burger-menu-class {
    display:block

  }

  .side-nav.closed {
    display: none
  }

  .spinneri__image {
    width: 150px;
    height: 150px;
    margin-left: 40%;
    background-size: contain;
    background-image: url("/public/assets/images/CCLogo.png");
  }
  
  .navbar-list-desktop{
    list-style: none;
    margin-left: 12%;
    margin-top: 7%;
  }

  .navbar-list-desktop li{
    font-size: 20px;
    padding-bottom: 20px;
   }


}


@keyframes circleAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
