.main{
  overflow: hidden;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #bdeaff url("/public/assets/images/bg-body-clouds.svg") no-repeat center center / cover;
  height: 100vh;
  text-align: center;
}

.firstTitle{
  font-size: 25px;
}

.rainbow {
  max-width: 500px;
  max-height: 500px
}

.round-card {
  width: 500px;
  height: 200px;
  border-radius: 20%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  text-align: center;
}


.salesService {
  background-color: #015254;
  height: 55vh;
}

.robot {
  max-width: 400px;
  max-height: 400px;
}

.salesforceBear {
  max-width: 400px;
  max-height: 400px;
  margin-left: 30%;
  margin-top: 5%;
}

.communityAnalytics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #bdeaff;
  height: 80vh;

}

.community{
  max-width: 500px;
  max-height: 500px;
}

.analitic{
  max-width: 500px;
  max-height: 500px;
}

.marketing{
  max-width: 500px;
  max-height: 500px;
}

.commerce{
  max-width: 500px;
  max-height: 500px;
}

.CCRobot{
  max-width: 700px;
  max-height: 700px;
}

.expertise{
  max-width: 200px;
  max-height: 200px;
}

.marketingCommerce {
  
  background: #bdeaff url("/public/assets/images/bg-body-clouds.svg") no-repeat center center / cover;
  height: 70vh;
  padding-top: 3em;

}

.salesPlatform {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #bdeaff;
  height: 70vh;
  position: relative;
}

.why-us {
  background: #467f3a;
  height: 115vh;
}

.information-salesPlatform {
  display: flex;
  gap: 4;
}

.topLineWhyUs{
  width: 100%;
  height: 220px;
}

.expertise{
  max-width: 200px;
  max-height: 200px;
}

.certified{
 max-width: 200px;
 max-height: 200px;
}

.track{
  max-width: 210px;
  max-height: 210px;
}

.solutions{
   max-width: 200px;
   max-height: 200px;
}

.strategies{
  max-width: 160px;
  max-height: 160px;
}

.results{
  max-width: 280px;
  max-height: 280px;
}

.customers{
  max-width: 200px;
  max-height: 200px;
}

.improvement{
  max-width: 200px;
  max-height: 200px;
}

.partnership{
  max-width: 250px;
  max-height: 250px;
}


.sectionsMenu {
  position: fixed;
  z-index: 10;
  top: 30%;
  left: 1%;
  opacity: 1;
}

.sectionsMenuHide {
  opacity: 0;
}

.sectionsList li {
  margin-bottom: 40%;
}

.sectionsList li a {
  color: #70d7ff;
  font-weight: 600;
}

.sectionsList li .linkActive {
  color: rgb(62, 19, 68)
}

.circleDesciption {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.34, rgb(122, 153, 217)), color-stop(0.5, rgb(73, 125, 189)), color-stop(0.76, rgb(28, 58, 148)));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.circleDesciption.active {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0.34, rgb(154, 136, 255)), color-stop(0.62, rgb(112, 98, 194)), color-stop(0.76, rgb(70, 59, 134)));
}



.info_section_sales {
  background-color: #006869;
  color: #ffffff;

}

.info_section_sales h4 {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;

  
}



.info_section_sales .info_contact .contact_link_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.info_section_sales .info_contact .contact_link_box a {
  margin: 5px 0;
  color: #ffffff;
}

.info_section_sales .info_contact .contact_link_box a i {
  margin-right: 5px;
}

.info_section_sales .info_contact .contact_link_box a:hover {
  color: #00bbf0;
}

.info_section_sales .info_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info_section_sales .info_social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  border-radius: 100%;
  margin-right: 10px;
  font-size: 24px;
}

.info_section_sales .info_social a:hover {
  color: #00bbf0;
}

.info_section_sales .info_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.info_section_sales .info_links a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  color: #ffffff;
}

.info_section_sales .info_links a:hover {
  color: #00bbf0;
}



.info_section_sales form button:hover {
  background-color: #007fa4;
}


.footer_section_sales {
  position: relative;
  background-color: #015254;
  text-align: center;
}

.footer_section_sales p {
  color: white;
  padding: 25px 0;
  margin: 0;
}

.footer_section_sales p a {
  color: inherit;
}


@media only screen and (max-width: 500px) {
  .sectionsMenu {
    top: 35%;
    left: -25px;
  }

  .circleDesciption {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }

}

@media only screen and (min-width: 501px) and (max-width: 700px) and (orientation: landscape) {
  .sectionsMenu {
    top: 13%;
    left: -20px;
  }

  .circleDesciption {
    width: 37px;
    height: 37px;
    font-size: 15px;
  }
}


@media only screen and (max-width: 400px) {

  .description {
    height: 150vh;
  }
  
  .firstTitle{
    font-size: 15px;
  }

  .round-card {
    width: 300px;
    height: 150px;
    border-radius: 20%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .round-card .content h5{
    font-size: 13px;
  }

  .round-card .content p{
    font-size: 13px;
  }

  .topLineWhyUs{
    width: 100%;
    height: 150px;
  }


  .salesService {
    height: 130vh;
  }

  .communityAnalytics{
    height: 110vh;
  }

  .marketingCommerce {

    height: 120vh;
    padding-top: 3em;
  
  }

  .salesPlatform {
    height: 110vh;

  }

  .why-us {
    height: 240vh;
  }
  


  .description .information{
    font-size: 14px;
  }

  .salesService .information{
    font-size: 16px;
  }

  .communityAnalytics .information{
    font-size: 16px;
    margin-top: 13px;
  }

  .marketingCommerce .information{
    font-size: 16px;
  }
  
  .salesPlatform .information{
    font-size: 16px;
  }

  .why-us .information{
    font-size: 16px;
  }



  .rainbow {
    max-width: 300px;
    max-height: 300px
  }


  .robot{
    max-width: 250px;
    max-height: 250px;
    margin-bottom: 30px;
  }

  .salesforceBear {
    max-width: 200px;
    max-height: 200px;
    margin-top: 40px;
  }

  .community{
    max-width: 300px;
    max-height: 300px;
  }

  .analitic{
    max-width: 300px;
    max-height: 300px;
  }

  .marketing{
    max-width: 300px;
    max-height: 300px;
  }

  .commerce{
    max-width: 350px;
    max-height: 350px;
  }
  
  .CCRobot{
    max-width: 280px;
    max-height: 280px;
  }

  .expertise{
    max-width: 160px;
    max-height: 160px;
  }

  .certified{
    max-width: 160px;
    max-height: 160px;
   }

   .track{
    max-width: 160px;
    max-height: 160px;
  }

  .solutions{
    max-width: 160px;
    max-height: 160px;
 }

 .strategies{
  max-width: 130px;
  max-height: 130px;
}

.results{
  max-width: 230px;
  max-height: 230px;
  }

  .customers{
    max-width: 160px;
    max-height: 160px;
  }

  .improvement{
    max-width: 160px;
    max-height: 160px;
  }

  .partnership{
    max-width: 200px;
    max-height: 200px;
  }

  .footer {
    height: 20vh;
    font-size: 13px;
  
  }


}

@media only screen and (min-width: 401px) and (max-width: 500px) {
  
  .description {
    height: 120vh;
  }

  .communityAnalytics{
    height: 110vh;
  }

  .marketingCommerce {
    height: 100vh;
  }



  .firstTitle{
    font-size: 15px;
  }

  .round-card {
    width: 400px;
    height: 170px;
    border-radius: 20%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    text-align: center;

  }

  .round-card .content h5{
    font-size: 16px;
  }

  .round-card .content p{
    font-size: 15px;
  }

  .salesService {
    background-color: #015254;
    height: 105vh;
  }

  .rainbow {
    max-width: 400px;
    max-height: 400px
  }

  .description .information{
    font-size: 18px;
  }

  .salesService .information{
    font-size: 19px;
  }

  .communityAnalytics .information{
    font-size: 19px;
  }

  .marketingCommerce .information{
    font-size: 19px;
  }
  
  .salesPlatform .information{
    font-size: 18px;
  }

  .why-us .information{
    font-size: 18px;
  }
  

  .robot{
    max-width: 250px;
    max-height: 250px;
    margin-bottom: 30px;
  }

  .salesforceBear {
    max-width: 250px;
    max-height: 250px;
    margin-top: 40px;
  }

  .community{
    max-width: 350px;
    max-height: 350px;
  }

  .analitic{
    max-width: 350px;
    max-height: 350px;
  }

  .marketing{
    max-width: 350px;
    max-height: 350px;
  }

  .commerce{
    max-width: 400px;
    max-height: 400px;
  }
  
  .CCRobot{
    max-width: 350px;
    max-height: 350px;
  }

  .expertise{
    max-width: 180px;
    max-height: 180px;
  }

  .certified{
    max-width: 180px;
    max-height: 180px;
   }

   .track{
    max-width: 180px;
    max-height: 180px;
  }

  .solutions{
    max-width: 180px;
    max-height: 180px;
 }

 .strategies{
  max-width: 150px;
  max-height: 150px;
}

.results{
  max-width: 250px;
  max-height: 250px;
  }

  .customers{
    max-width: 180px;
    max-height: 180px;
  }

  .improvement{
    max-width: 180px;
    max-height: 180px;
  }

  .partnership{
    max-width: 200px;
    max-height: 200px;
  }
  
  
  .salesPlatform {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #bdeaff;
    height: 75vh;
    position: relative;
  }

  .salesPlatform {
    height: 95vh;
  }

  .topLineWhyUs{
    width: 100%;
    height: 150px;
  }

  .why-us {
    height: 210vh;
  }

  .footer {
    font-size: 15px;
  }
  

}


@media only screen and (min-width: 501px) and (max-width: 800px) {
  
  .firstTitle{
    font-size: 15px;
  }

  .round-card {
    width: 450px;
    height: 200px;
    border-radius: 20%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    text-align: center;

  }

  .salesService {
    background-color: #015254;
    height: 85vh;
  }

  .rainbow {
    max-width: 400px;
    max-height: 400px
  }

  .description .information{
    font-size: 18px;
  }

  .salesService .information{
    font-size: 19px;
  }

  .communityAnalytics .information{
    font-size: 19px;
  }

  .marketingCommerce .information{
    font-size: 19px;
  }
  
  .salesPlatform .information{
    font-size: 18px;
  }

  .why-us .information{
    font-size: 18px;
  }
  

  .robot{
    max-width: 250px;
    max-height: 250px;
    margin-bottom: 30px;
  }

  .salesforceBear {
    max-width: 250px;
    max-height: 250px;
    margin-top: 40px;
  }

  .community{
    max-width: 350px;
    max-height: 350px;
  }

  .analitic{
    max-width: 350px;
    max-height: 350px;
  }

  .marketing{
    max-width: 350px;
    max-height: 350px;
  }

  .commerce{
    max-width: 400px;
    max-height: 400px;
  }
  
  .CCRobot{
    max-width: 350px;
    max-height: 350px;
  }

  .expertise{
    max-width: 180px;
    max-height: 180px;
  }

  .certified{
    max-width: 180px;
    max-height: 180px;
   }

   .track{
    max-width: 180px;
    max-height: 180px;
  }

  .solutions{
    max-width: 180px;
    max-height: 180px;
 }

 .strategies{
  max-width: 150px;
  max-height: 150px;
}

.results{
  max-width: 250px;
  max-height: 250px;
  }

  .customers{
    max-width: 180px;
    max-height: 180px;
  }

  .improvement{
    max-width: 180px;
    max-height: 180px;
  }

  .partnership{
    max-width: 200px;
    max-height: 200px;
  }
  
  
  .salesPlatform {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #bdeaff;
    height: 75vh;
    position: relative;
  }

  .marketingCommerce {
    height: 75vh;
    padding-top: 3em;
  }

  .salesPlatform {
    height: 95vh;
  }

  .topLineWhyUs{
    width: 100%;
    height: 150px;
  }

  .why-us {
    height: 165vh;
  }

  .footer {
    font-size: 15px;
  }
  

}