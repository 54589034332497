.annotation {
    transition: all 0.5s ease-in-out;
    box-shadow: inset 0 0 0 0.09px white;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 7px;
    font-weight: 300;
    background: #294891;
    color: white;
    padding: 2px 10px;
    border-radius: 20px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .annotation:hover {
    box-shadow: inset 250px 0 0 0 white;
    color: black;
  }
  
  .annotation.hide {
    display: none;
  }