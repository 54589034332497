
.sidenav-mobile {
  position: fixed;
  width: 50%;
  right: 0;
  height: 100%;
  z-index: 14;
  background: linear-gradient(to bottom, #2473da,#5f9ae7,#80ade9, #5094ec);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.navbar-list {
  list-style: none;
}

.navbar-list li {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 5%;
  font-size: 20px;
}

.navbar-list li h5{ 
  color: #1e20a3;
  font-weight: 800;
  margin-bottom: -20px;
 }

 .navbar-list li a{ 
  color: #2428cc;
  font-weight: 600;
 }


/*Menu Svg*/
.icon-menu-mob {
  position: fixed;
  margin-top: 70%;
  margin-left: 43%;
  z-index: 13;
  cursor: pointer;
}


.icon-menu-mob.open {
  width: 200px;
  margin-left: 40%;
}

.icon-menu-mob.close {
  width: 38px;
  right: 3%; 
}

/* Shigjeta Container */
.svg-container {
  display: flex;
  align-items: center;
  width: "69";
  height: "591";
  position: fixed;

}
.label-check {
  display: none;
}
.hamburger-label {
  width: 40px;
  height: 32px;
  display: block;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 40px;
  position: absolute;
}
.hamburger-label.open {
  margin-left: 5px;
}
.hamburger-label div {
  width: 25px;
  height: 3px;
  background-color: #2428cc;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.hamburger-label .line1 {
  width: 26px;
  position: absolute;
}
.hamburger-label .line2 {
  width: 15px;
  position: absolute;
  left: auto;
  right: 15px;
}
.hamburger-label .line3 {
  width: 10px;
  position: absolute;
  left: auto;
  right: 15px;
}

.line1 {
  transition: all 0.3s;
}
.line2 {
  margin: 8px 0 0 0;
  transition: 0.3s;
}
.line3 {
  margin: 16px 0 0 0;
  transition: 0.3s;
}

#label-check:checked + .hamburger-label .line1 {
  transform: rotate(35deg) scaleX(0.4) translate(28px, -1px);
  border-radius: 50px 50px 50px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
#label-check:checked + .hamburger-label .line3 {
  transform: rotate(-35deg) scaleX(0.4) translate(28px, 1px);
  border-radius: 0 50px 50px 50px;
  width: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
#label-check:checked + .hamburger-label .line2 {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.teams{
  display:list-item;
}


