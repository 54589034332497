@import url('https://fonts.googleapis.com/css?family=Cairo');

.titleTeam {
	font-family: "Cairo";
	text-align: center;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	letter-spacing: 2.5em;
	line-height:1.8;
	width:100%;
    background-color:#1e2a44;
}

.text1Team {
	background-image: url("https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif");
	background-size: contain;
	color: transparent;
	background-clip: text;
	text-transform: uppercase;
	font-size: 120px;
	margin-bottom: 10%;
}
.text2Team {
	background-image: url("https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif");
	background-size: contain;
	color: transparent;
	background-clip: text;
	text-transform: uppercase;
	font-size: 120px;
	
}
.textSalesforce {
	color: white;
	font-size: 70px;
	margin-top: 10%;
}

@media only screen and (max-width: 500px) {

	.text1Team {
		font-size: 35px;
		letter-spacing: 0.3em;
		margin-left: 4%;
	}
	.text2Team {
		font-size: 35px;
		letter-spacing: 0.5em;
		margin-left: 5%;
	}
	.textSalesforce {
		font-size: 25px;
		margin-top: 10%;
		margin-left: 2%;
		letter-spacing: 0.1em;
	}
  }
  
  @media only screen and (min-width: 501px) and (max-width: 1024px){

	.text1Team {
		font-size: 70px;
		letter-spacing: 0.3em;
		margin-left: 4%;
	}
	.text2Team {
		font-size: 70px;
		letter-spacing: 0.5em;
		margin-left: 5%;
	}
  }