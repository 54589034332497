@import url('https://fonts.googleapis.com/css?family=Cairo');

.titleJava {
	font-family: "Cairo";
	text-align: center;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	letter-spacing: 2.5em;
	line-height:1.8;
	width:100%;
}

.text1Java {
	text-transform: uppercase;
	margin-bottom: 6%;
}
.text2Java {
	text-transform: uppercase;
}
.textJava {
	color: rgb(0, 57, 110);
	font-size: 40px;
	margin-top: 8%;
	letter-spacing: 0.3em;
}

  
  h1 div {
	position: relative;
	top: 20px;
	display: inline-block;
	animation: bounce 0.45s ease infinite alternate;
	font-size: 80px;
	color: #357cff4d;
	text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
	  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
	  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  }
  
  h1 div:nth-child(2) {
	animation-delay: 0.1s;
  }
  
  h1 div:nth-child(3) {
	animation-delay: 0.2s;
  }
  
  h1 div:nth-child(4) {
	animation-delay: 0.3s;
  }
  
  h1 div:nth-child(5) {
	animation-delay: 0.4s;
  }
  
  h1 div:nth-child(6) {
	animation-delay: 0.5s;
  }
  
  h1 div:nth-child(7) {
	animation-delay: 0.6s;
  }
  
  h1 div:nth-child(8) {
	animation-delay: 0.2s;
  }
  
  h1 div:nth-child(9) {
	animation-delay: 0.3s;
  }
  
  h1 div:nth-child(10) {
	animation-delay: 0.4s;
  }
  
  h1 div:nth-child(11) {
	animation-delay: 0.5s;
  }
  
  h1 div:nth-child(12) {
	animation-delay: 0.6s;
  }
  
  h1 div:nth-child(13) {
	animation-delay: 0.7s;
  }
  
  h1 div:nth-child(14) {
	animation-delay: 0.8s;
  }
  


@media only screen and (max-width: 500px) {

	.text1Java {
		font-size: 35px;
		letter-spacing: 0.3em;
		margin-left: 4%;
	}
	.text2Java {
		font-size: 35px;
		letter-spacing: 0.5em;
		margin-left: 5%;
	}
	.textJava {
		font-size: 25px;
		margin-top: 20%;
		margin-left: 2%;
		letter-spacing: 0.1em;
	}
	h1 div {
		font-size: 50px;
	}
  .wave {
    width: 240%;
    height: 12em;
  }

  }

  @media only screen and (min-width: 501px) and (max-width: 1200px){

	.text1Java {
		font-size: 230px;
		letter-spacing: 0.25em;
		margin-left: 80px;
	}
	.text2Java {
		font-size: 100px;
		letter-spacing: 0.4em;
		margin-left: 80px;
	}
	.textJava {
		font-size: 30px;
		margin-top: 20%;
		margin-left: 30px;
		letter-spacing: 0.1em;
	}
	h1 div {
		font-size: 50px;
	}
  .wave {
    width: 240%;
    height: 12em;
  }

  }
  





  @keyframes bounce {
	100% {
	  top: -15px;
	  text-shadow: 0 1px 0 #153064, 0 2px 0 #153064, 0 3px 0#153064, 0 4px 0 #153064,
		0 5px #153064, 0 6px 0 #153064, 0 7px 0 #153064, 0 8px 0 #153064, 0 9px 0 #153064,
		0 50px 25px rgba(0, 0, 0, 0.2);
	}
  }
