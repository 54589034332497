

.panel-descriptionScene{
    height: 100vh; 
    width: 100%;
    top: 0;
    bottom: 0;
    border: 0; 
    background: linear-gradient(to bottom, #3535cc, #69d4ff);
}

.panel-salesforceScene{
    height: 100vh;
    width: 100%;
    margin-top: -1px;
    bottom: 0;
    border: 0; 
    background:#380049;
} 

.panel-tunnelScene{
    height: 100vh;
    width: 100%;
    margin-top: -1px;
    bottom: 0;
    border: 0; 
    background-color: #020202;
} 

.panel-javaScene{
    height: 100vh;
    width: 100%;
    margin-top: -1px;
    bottom: 0;
    border: 0; 
   background-color: #00000a;
}


.panel-webDevelopmentScene{
    height: 100vh;
    width: 100%;
    margin-top: -1px;
    bottom: 0;
    border: 0; 
    background: linear-gradient(to right,#031e4f,#021e54,#011e56);
}

.javaText{
    font-size: 6.5em;
    color: rgb(16, 0, 88);
    font-weight: 300;
}

@media only screen and (max-width: 500px) {
	.javaText{
        font-size: 2em;
        font-weight: 300;
        color: rgb(16, 1, 88);
    }
}
  
@keyframes movingGradient {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}