
.team {
	  display: flex;
    justify-content: center;
    align-items: center;
    background-color: #416aa3;
    height: 100vh;
}

.teamText {
    font-family: "Montserrat Medium";
    max-width: 40ch;
    text-align: center;
    transform: scale(0.95);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    margin-bottom: 10vh;
  }

  .text {
    color: white;
    font-size: 60px;
    margin-top: 15%;
    text-align: center;
  }

.letters {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
    color: #357cff4d;
    text-shadow: 
    0 1px 0 #ccc, 
    0 2px 0 #357cff4d, 
    0 3px 0 #357cff75,
    0 4px 0 #357cff8e,
    0 5px 0 #357cffb9, 
    0 6px 0 transparent,
    0 7px 0 transparent, 
    0 8px 0 transparent,
    0 9px 0 transparent, 
    0 10px 10px rgba(0, 0, 0, 0.4);
  }
  
  /*CCT*/
  .letters:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  /*AOE*/
  .letters:nth-child(2) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  /*LDA*/
  .letters:nth-child(3) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  /*MEM*/
  .letters:nth-child(4) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  /*R*/
  .letters:nth-child(5) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  /*S*/
  .letters:nth-child(6) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  @media only screen and (max-width: 500px) {
    .teamText {
    animation: scalePhone 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    }
  
    
  }

  
    @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

    @keyframes scale {
    100% {
      transform: scale(2.5);
    }
  }
  @keyframes scalePhone {
    100% {
      transform: scale(2);
    }
  }

  