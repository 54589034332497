@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Open+Sans:wght@300;400;600;700&display=swap");
body {
  font-family: "Open Sans", sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.layout_padding2-top {
  padding-top: 75px;
}

.layout_padding2-bottom {
  padding-bottom: 75px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

.heading_container_web {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.heading_container_web h2 {
  position: relative;
  font-weight: bold;
  margin-bottom: 0;
}

.heading_container_web h2 span {
  color: #00bbf0;
}

.heading_container_web p {
  margin-top: 10px;
  margin-bottom: 0;
}

.heading_container_web.heading_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}


.hero_area_web {
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
          background-color: #00204a;
}

.hero_area_web .hero_bg_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  overflow: hidden;
  z-index: -1;
}

.hero_area_web .hero_bg_box .bg_img_box {
  min-width: 100%;
  min-height: 100%;
}

.hero_area_web .hero_bg_box img {
  min-width: 100%;
  min-height: 100%;
}


.header_section_web {
  padding: 15px 0px;
  position: fixed;
  z-index: 1;
  background-color: #00204a;
  width: 100%;
}

.header_section_web .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.navbar-brand_web span {
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
}

.custom_nav-container_web {
  padding: 0;
}

.custom_nav-container_web .navbar-nav {
  margin-left: 40%;
  margin-bottom: 0.1%;
}

.custom_nav-container_web .navbar-nav .nav-item .nav-link {
  padding: 5px 20px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container_web .navbar-nav .nav-item:hover .nav-link, .custom_nav-container_web .navbar-nav .nav-item.active .nav-link {
  color: #00bbf0;
}

.custom_nav-container_web .navbar-nav .nav-item.active .nav-link {
  font-weight: 600;
}


.home_web {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 45px 0 145px 0;
  background-color: #00204a;
}

.home_web .container .technology_container .img-box1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
   align-items: center;
   border-radius: 100%; /* Adjust radius as needed */
   border: 5px solid #ffffff;
 width: 120px;
 height: 109px;
 
}

.home_web .container .technology_container .img-box1 img {
  border-radius: 100%; 
}

.home_web .container .technology_container .img-box1 .img1 {
  width: 180px;
  height: 120px
}

.home_web .container .technology_container .img-box1 .img2 {
  width: 95px;
  height: 80px
}

.home_web .container .technology_container .img-box1 .img3 {
  width: 170px;
  height: 110px
}

.home_web .container .technology_container .img-box1 .img4 {
  width: 80px;
  height: 80px
}

.home_web .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.home_web .detail-box {
  color: #00204a;
}


.home_web .detail-box h1 {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #ffffff;
}


.home_web .detail-box p {
  color: #fefefe;
  font-size: 14px;
}

.home_web .detail-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -5px;
  margin-top: 25px;
}

.home_web .detail-box .btn-box a {
  margin: 5px;
  text-align: center;
  width: 165px;
}

.home_web .detail-box .btn-box .btn1 {
  display: inline-block;
  padding: 10px 15px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.home_web .detail-box .btn-box .btn1:hover {
  background-color: #007fa4;
}

.home_web .detail-box .btn-box .btn2 {
  display: inline-block;
  padding: 10px 15px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.home_web .detail-box .btn-box .btn2:hover {
  background-color: black;
}

.home_web .img-box {
  margin: 45px 0;
}

.home_web .img-box img {
  width: 100%;
  -webkit-animation: upDown 5s infinite;
          animation: upDown 5s infinite;
}



.distinction_web {
  position: relative;
}

.distinction_web .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-top: 45px;
  background-color: #f8f8f9;
  padding: 20px;
  border-radius: 5px;
  height: 290px;
}

.distinction_web .box .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 125px;
  min-width: 75px;
  height: 75px;
  margin-bottom: 15px;
}

.distinction_web .box .img-box img {
  max-width: 100%;
  max-height: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.distinction_web .box .detail-box h5 {
  font-weight: bold;
  text-transform: uppercase;
}

.distinction_web .box .detail-box p {
  font-size: 15px;
  margin-top: 5%;
}

.distinction_web .box .detail-box a {
  color: #00204a;
  font-weight: 600;
}

.distinction_web .box .detail-box a:hover {
  color: #00bbf0;
}

.distinction_web .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 45px;
}

.distinction_web .btn-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.distinction_web .btn-box a:hover {
  background-color: #007fa4;
}

.story_web {
  background-color: #00204a;
  color: #ffffff;
}

.story_web .heading_container_web {
  margin-bottom: 45px;
}

.story_web .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.story_web .img-box img {
  max-width: 100%;
}

.story_web .detail-box h3 {
  font-weight: bold;
}

.story_web .detail-box p {
  margin-top: 15px;
}

.story_web .detail-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  margin-top: 15px;
}

.story_web .detail-box a:hover {
  background-color: #007fa4;
}



.projects_web .box {
  margin-top: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.projects_web .box .img-box {
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
  min-width: 120px;
  min-height: 120px;
  border-radius: 100%;
  border: 5px solid #00204a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.projects_web .box .img-box .img-box-project1 {
  width: 190px;
  height: 180px;
  border-radius: 100%;
}

.projects_web .box .img-box .img-box-project2 {
  width: 191px;
  height: 187px;
  border-radius: 100%;

}

.projects_web .box .img-box .img-box-project3 {
  width: 191px;
  height: 192px;
  border-radius: 100%;
  
}

.projects_web .box .img-box .img-box-project4 {
  width: 192px;
  height: 192px;
  border-radius: 100%;

}

.projects_web .box h5 {
  font-weight: bold;
  margin-bottom: 10px;
}

.projects_web .box p {
  margin-bottom: 0;
}

.projects_web .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 45px;
}

.projects_web .btn-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.projects_web .btn-box a:hover {
  background-color: #007fa4;
}


.methodology_web {
  text-align: center;
  background-color: #00204a;
  color: #ffffff;
}

.methodology_web .heading_container_web {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.methodology_web .team_container {
  padding: 0 15px;
}

.methodology_web .team_container .box {
  padding: 35px 0 0 0;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all .1s;
  transition: all .1s;
  margin-top: 45px;
  background: -webkit-gradient(linear, left top, left bottom, from(#002759), to(#002b64));
  background: linear-gradient(to bottom, #002759, #002b64);
  height: 340px;
}

.methodology_web .team_container .box .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.methodology_web .team_container .box .img-box img {
  width: 110px;
  height: 95px;
  border-radius: 100%;
  border: 5px solid #ffffff;
  
}

.methodology_web .team_container .box .detail-box {
  margin: 25px 0;
}

.methodology_web .team_container .box .detail-box h5 {
  color: #00bbf0;
  font-weight: 600;
  position: relative;
}

.methodology_web .team_container .box .detail-box p {
font-size: 15px;
margin-top: 5%;
}

.methodology_web .team_container .box .social_box {
  padding: 10px 45px 20px 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.methodology_web .team_container .box .social_box a {
  color: #ffffff;
  font-size: 22px;
}

.methodology_web .team_container .box .social_box a:hover {
  color: #00bbf0;
}


.info_section_web {
  background: linear-gradient(to bottom, #002759, #002b64);
  color: #ffffff;
  padding: 45px 0 15px 0;
}

.info_section_web h4 {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;

}

.info_section_web .info_col {
  margin-bottom: 30px;
}

.info_section_web .info_contact .contact_link_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.info_section_web .info_contact .contact_link_box a {
  margin: 5px 0;
  color: #ffffff;
}

.info_section_web .info_contact .contact_link_box a i {
  margin-right: 5px;
}

.info_section_web .info_contact .contact_link_box a:hover {
  color: #00bbf0;
}

.info_section_web .info_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info_section_web .info_social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  border-radius: 100%;
  margin-right: 10px;
  font-size: 24px;
}

.info_section_web .info_social a:hover {
  color: #00bbf0;
}

.info_section_web .info_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.info_section_web .info_links a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
  color: #ffffff;
}

.info_section_web .info_links a:hover {
  color: #00bbf0;
}

.info_section_web form input {
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  width: 100%;
  height: 45px;
  color: #ffffff;
  outline: none;
}

.info_section_web form input::-webkit-input-placeholder {
  color: #ffffff;
}

.info_section_web form input:-ms-input-placeholder {
  color: #ffffff;
}

.info_section_web form input::-ms-input-placeholder {
  color: #ffffff;
}

.info_section_web form input::placeholder {
  color: #ffffff;
}

.info_section_web form button {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 10px 55px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  margin-top: 15px;
}

.info_section_web form button:hover {
  background-color: #007fa4;
}


.footer_section_web {
  position: relative;
  background-color: #00204a;
  text-align: center;
}

.footer_section_web p {
  color: white;
  padding: 25px 0;
  margin: 0;
}

.footer_section_web p a {
  color: inherit;
}

@media (max-width: 500px) {


  .home_web{
    margin-top: 17%;
  }

  .custom_nav-container_web .navbar-nav {
    margin-left: -25px;
    margin-bottom: 0.1%;
  }
  
  .custom_nav-container_web .navbar-nav .nav-item .nav-link {
    padding: 9px 9px;
    font-size: 12px;
  }

  .home_web .detail-box h1{
    font-size: 30px;
  }

  .home_web .detail-box p{
    font-size: 14px;
  }
  .home_web .img-box img{
    width: 300px;
    height: 300px;
    margin-left: 10%;
    
  }

  .home_web .container .technology_container{
   margin-right: 5%;
}

  .home_web .container .technology_container .img-box1 {
    width: 80px;
    height: 80px;
    
   }
 
   .home_web .container .technology_container .img-box1 .img1 {
     width: 120px;
     height: 80px
   }
   
   .home_web .container .technology_container .img-box1 .img2 {
     width: 70px;
     height: 60px
   }
   
   .home_web .container .technology_container .img-box1 .img3 {
     width: 80px;
     height: 70px
   }
   
   .home_web .container .technology_container .img-box1 .img4 {
     width: 60px;
     height: 55px
   }
   
  .story_web .detail-box{
    margin-top: 8%;
    margin-left: 5%;
  }

  .stucture_container_web {
    width: 340px;
    height: 100px;
    margin-top: 10%;
  }






  
}

@media ((min-width : 501px) and (max-width: 600px)) {

  .home_web .container .technology_container .img-box1 {
   width: 80px;
   height: 80px;
   
  }

  .home_web .container .technology_container .img-box1 .img1 {
    width: 120px;
    height: 80px
  }
  
  .home_web .container .technology_container .img-box1 .img2 {
    width: 60px;
    height: 50px
  }
  
  .home_web .container .technology_container .img-box1 .img3 {
    width: 90px;
    height: 80px
  }
  
  .home_web .container .technology_container .img-box1 .img4 {
    width: 60px;
    height: 55px
  }
  .custom_nav-container_web .navbar-nav {
    margin-left: -10px;
    margin-bottom: 0.1%;
  }
}

@media ((min-width : 601px) and (max-width: 900px)) {

  .home_web .container .technology_container .img-box1 {
   width: 80px;
   height: 80px;
   
  }

  .home_web .container .technology_container .img-box1 .img1 {
    width: 120px;
    height: 80px
  }
  
  .home_web .container .technology_container .img-box1 .img2 {
    width: 60px;
    height: 50px
  }
  
  .home_web .container .technology_container .img-box1 .img3 {
    width: 90px;
    height: 80px
  }
  
  .home_web .container .technology_container .img-box1 .img4 {
    width: 60px;
    height: 55px
  }
  .custom_nav-container_web .navbar-nav {
    margin-left: -10px;
    margin-bottom: 0.1%;
  }
}

@media ((min-width: 901px) and (max-width: 1199px)) {

  .home_web .container .technology_container .img-box1 {
   width: 80px;
   height: 80px;
   
  }

  .home_web .container .technology_container .img-box1 .img1 {
    width: 120px;
    height: 80px
  }
  
  .home_web .container .technology_container .img-box1 .img2 {
    width: 60px;
    height: 50px
  }
  
  .home_web .container .technology_container .img-box1 .img3 {
    width: 90px;
    height: 80px
  }
  
  .home_web .container .technology_container .img-box1 .img4 {
    width: 60px;
    height: 55px
  }
  .custom_nav-container_web .navbar-nav {
    margin-left: 200px;
    margin-bottom: 0.1%;
  }
}

@media (min-width:992px){

  .projects_web .projects_web_container .row{
    margin-left: 13%;
    width: 100%;
  }
  
}

@-webkit-keyframes upDown {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
  50% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
}

@keyframes upDown {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
  50% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
}