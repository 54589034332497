@font-face {
  font-family: 'Contender';
  src: url('/public/assets/fonts/AnggiedisplayRegular-jEpWj.otf') format('opentype');
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.overlay--disable {
  pointer-events: none;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, #3535cc, #69d4ff);
  transition: opacity 2s ease-in-out;
}

.loader--disappear {
  opacity: 0;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.logo {
  font-family: 'Contender', sans-serif;
  background: linear-gradient(30deg, #8798f7, #43ffff, #3ed657);
  background-clip: text;
  color: transparent;
  font-size: 5.8rem;
  font-weight: 600;
  width: 45rem;
  letter-spacing: 0.5rem;
  padding: 0;
  margin: 0;
  transform: translateY(-50%);
  position: absolute;
  top: 45vh;
  animation: fadeUp 2.4s ease-in-out;
  transition: all 2s ease-in-out;
}

.intro--disappear .logo {
  top: 42px;
  transform: scale(0.25);
  transform-origin: center top;
  font-size: 11rem;
  letter-spacing: 0rem;
}


.calmText {
  position: absolute;
  left: 4%;
  top: 1.4%;
  font-size: 35px;
  color: #69d4ff;
  font-family: 'Contender', sans-serif;
}

.intro--disappear .calmText {
  opacity: 1;
  animation: fadeOut 1s ease-in-out forwards;
}

.spinner__image {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 0.5%;
  top: 0.5%;
  background-size: contain;
  background-image: url("/public/assets/images/CCLogo.png");
}

.intro--disappear .spinner__image {
  opacity: 1;
  animation: fadeOut 1s ease-in-out forwards;
}


.intro .mouseAnimation{
  opacity: 0;
}

.intro--disappear .mouseAnimation{
  animation: fadeIn 1s ease-in-out 1.5s forwards;
    width: 40px;
    height: 75px;
    border: 3px solid rgb(62, 214, 214);
    border-radius: 60px;
    position: relative;
    margin-bottom: 18em;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgb(62, 214, 214);
      border-radius: 50%;
      opacity: 1;
      animation: wheel 2s infinite;
      -webkit-animation: wheel 2s infinite;
  }
}

.overlay--scrolled .mouseAnimation {
  animation: fadeOut 1s ease-in-out forwards;
}

.intro__scroll {
  opacity: 0;
  color: rgb(62, 214, 214);
  font-size: 1.8rem;
  letter-spacing: 0.05rem;
  position: absolute;
  top: 9.5vh;
  font-family: 'Contender', sans-serif;
}

.intro--disappear .intro__scroll {
  font-size: 31px;
  animation: fadeIn 1s ease-in-out 1.5s forwards;
}

.overlay--scrolled .intro__scroll {
  animation: fadeOut 1s ease-in-out forwards;
}

.intro__scroll1 {
  opacity: 0;
  background: linear-gradient(90deg,  #69d4ff, #3ed657);
  background-clip: text;
  color: transparent;
  font-size: 1.8rem;
  letter-spacing: 0.05rem;
  position: absolute;
  top: 14.2vh;
  font-family: 'Contender', sans-serif;
}

.intro--disappear .intro__scroll1 {
  font-size: 29px;
  animation: fadeIn 2s ease-in-out 1.5s forwards;
}

.overlay--scrolled .intro__scroll1 {
  animation: fadeOut 1s ease-in-out forwards;
}

.explore {
  padding: 1rem 2rem;
  border: none;
  font-family: 'Contender', sans-serif;
  font-size: 1.25rem;
  letter-spacing: 0.25rem;
  color: #3535cc;
  border-radius: 32px;
  cursor: pointer;
  display: inline-block;
  margin-top: 320px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.42s ease-in-out;
  animation: fadeIn 2.4s ease-in-out 2s forwards;
  opacity: 0;
}

.explore:hover {
  color: white;
}

.explore:hover:before {
  width: 200%;
  height: 300%;
  border-radius: 100%;
}

.explore:before {
  content: "";
  background: #3535cc;
  position: absolute;
  width: 0%;
  bottom: 0;
  left: 50%;
  height: 0%;
  transition: 0.42s ease-in-out;
  z-index: -1;
  transform: translateX(-50%) translateY(50%);
}

.intro--disappear .explore {
  animation: fadeOut 1s ease-in-out forwards;
}

.outro {
  position: absolute;
  top: 45%;
  padding-left: 44%;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  transition: opacity 4s ease-in-out;
}

.outro--appear {
  opacity: 1;
}

.outro__text {
  font-family: 'Contender', sans-serif;
  font-size: 2rem;
  color: rgb(147, 250, 236)
}



/* Mouse Logo*/


.mouse_scroll {
  width: 50px;
  height: 50px;
  margin-top: 3%;
  margin-left: 7.5%;
}


.m_scroll_arrows {
  display: block;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg); 
  transform: rotate(45deg);
  border-right: 3px solid rgb(147, 250, 236);
  border-bottom: 3px solid rgb(147, 250, 236);
  margin: 0 0 3px 4px;
  width: 26px;
  height: 26px;
}


.one {
  margin-top: 10px;
}

.one, .two, .three{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

.one {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  animation-delay: alternate;
}

.two {
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  animation-delay: .2s;
  animation-direction: alternate; 
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  animation-delay: .3s;
  animation-direction: alternate; 
  margin-top: -6px;
}

.mouse {
  height: 52px;
  width: 34px;
  border-radius: 15.5px;
  transform: none;
  border: 3px solid rgb(147, 250, 236);
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: rgb(147, 250, 236);
  position: relative;
  height: 4px;
  width: 4px;
  border: 3px solid rgb(147, 250, 236);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}


.mouse-hide {
  height: 52px;
  width: 34px;
  border-radius: 15.5px;
  transform: none;
  border: 3px solid rgb(147, 250, 236);
  display: none;
}

.wheel-hide {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: rgb(147, 250, 236);
  position: relative;
  height: 4px;
  width: 4px;
  border: 3px solid rgb(147, 250, 236);
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: none;
}

.wheel-hide {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
  display: none;
}



/* RESPONSIVE */


@media screen and (max-width: 400px) {
  .logo {
    font-size: 1.3rem;
    width: 17.5rem;
    letter-spacing: 0.05rem;
    margin-top: 10px;
  }

  .intro--disappear .logo {
    top: 15px;
    transform: scale(0.4);
    transform-origin: center top;
    font-size: 3.2rem;
    letter-spacing: 0.02rem;
    width: 39rem;
  }

  .intro--disappear .intro__scroll{
   font-size: 1.1rem;
   margin-top: -0.5rem;
  }

  .intro--disappear .intro__scroll1{
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 4.8rem;
   }

  .spinner__image {
    width: 90px;
    height: 90px;
    
  }

  .outro {
    right: 10px;
    width: 420px;
  }

  .outro__text {
    font-size: 1.45rem;
    margin-bottom: 2rem;
  }

  .explore {
      padding: 0.7rem 1.5rem;
      font-size: 1rem;
      letter-spacing: 0.25rem;
      margin-top: 180px;
    }

    .mouse_scroll {
      width: 24px;
      height: 50px;
      margin-left: 80px;
    }

    .m_scroll_arrows
    {   
      border-bottom: 2px solid rgb(147, 250, 236);
      border-right: 2px solid rgb(147, 250, 236);
      margin: 0 0 21px 4px;
      width: 16px;
      height: 16px;
    }

    .mouse {
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      display: none;

    }
    .wheel {
      height: 5px;
      width: 2px;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }


    .mouse-hide {
      display: block;
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      margin-top: -10px;
    }
    .wheel-hide {
      height: 5px;
      width: 2px;
      display: block;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
    }

    .one, .two, .three
    {
      rotate: 180deg;
      
    }

    .two
    {
      margin-top: -20px;
    }

    .three
    {
      margin-top: -20px;
    }


    .calmText{    
      left: 70px;
      top: 15px;
      font-size: 21px;
    }
    
    .spinner__image {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0.5%;
      top: 0.5%;
    }


    .intro--disappear .mouseAnimation{
      width: 22px;
      height: 43px;
      border: 2.2px solid rgb(62, 214, 214);
      margin-bottom: 15.5em;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 10px;
        left: 52.77%;
        transform: translateX(-50%);
        background-color: rgb(62, 214, 214);
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
    }
  }
  

}

@media screen and (min-width: 401px) and (max-width: 768px) {
  .logo {
    font-size: 1.3rem;
    width: 17.9rem;
    letter-spacing: 0.05rem;
    margin-top: 10px;
  }

  .intro--disappear .logo {
    top: 15px;
    transform: scale(0.4);
    transform-origin: center top;
    font-size: 3.2rem;
    letter-spacing: 0.02rem;
    width: 39rem;
  }

  .intro--disappear .intro__scroll{
   font-size: 1.1rem;
   margin-top: -0.5rem;
  }

  .intro--disappear .intro__scroll1{
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 4.8rem;
   }

  .spinner__image {
    width: 90px;
    height: 90px;
    
  }

  .outro {
    width: 500px;
    right: 10px;
  }

  .outro__text {
    font-size: 1.45rem;
    margin-left: 2.3rem;
    margin-bottom: 2rem;
  }

  .explore {
      padding: 0.7rem 1.5rem;
      font-size: 1rem;
      letter-spacing: 0.25rem;
      margin-top: 180px;
    }

    .mouse_scroll {
      width: 24px;
      height: 50px;
      margin-left: 116px;
    }

    .m_scroll_arrows
    {   
      border-bottom: 2px solid rgb(147, 250, 236);
      border-right: 2px solid rgb(147, 250, 236);
      margin: 0 0 21px 4px;
      width: 16px;
      height: 16px;
    }

    .mouse {
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      display: none;

    }
    .wheel {
      height: 5px;
      width: 2px;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }


    .mouse-hide {
      display: block;
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      margin-top: -10px;
    }
    .wheel-hide {
      height: 5px;
      width: 2px;
      display: block;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
    }

    .one, .two, .three
    {
      rotate: 180deg;
      
    }

    .two
    {
      margin-top: -20px;
    }

    .three
    {
      margin-top: -20px;
    }


    .calmText{    
      left: 70px;
      top: 15px;
      font-size: 21px;
    }
    
    .spinner__image {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0.5%;
      top: 0.5%;
    }


    .intro--disappear .mouseAnimation{
      width: 22px;
      height: 43px;
      border: 2.2px solid rgb(62, 214, 214);
      margin-bottom: 10.5em;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 10px;
        left: 52.77%;
        transform: translateX(-50%);
        background-color: rgb(62, 214, 214);
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
    }
  }
  

}

@media screen and (min-width: 769px) and (max-width: 992px){

  .logo {
    font-size: 2.5rem;
    width: 31rem;
    letter-spacing: 0.05rem;
    margin-top: 10px;
  }


  .intro--disappear .logo {
    top: 15px;
    transform: scale(0.8);
    transform-origin: center top;
    font-size: 2.8rem;
    letter-spacing: 0.02rem;
    width: 34rem;
  }

  .intro--disappear .intro__scroll{
   font-size: 1.6rem;
   margin-top: 0.4rem;
  }

  .intro--disappear .intro__scroll1{
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 6rem;
   }

  .spinner__image {
    width: 90px;
    height: 90px;
    
  }

  .outro {
    width: 700px;
    right: 230px;
  }

  .outro__text {
    font-size: 1.8rem;
    margin-left: 2.3rem;
    margin-bottom: 2rem;
  }

  .explore {
      padding: 0.6rem 1.6rem;
      font-size: 1.2rem;
      letter-spacing: 0.25rem;
      margin-top: 180px;
    }

    .mouse_scroll {
      width: 24px;
      height: 50px;
      margin-left: 144px;
    }

    .m_scroll_arrows
    {   
      border-bottom: 2px solid rgb(147, 250, 236);
      border-right: 2px solid rgb(147, 250, 236);
      margin: 0 0 21px 4px;
      width: 16px;
      height: 16px;
    }

    .mouse {
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }
    .wheel {
      height: 5px;
      width: 2px;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }


    .mouse-hide {
      display: block;
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      margin-top: -10px;
    }
    .wheel-hide {
      height: 5px;
      width: 2px;
      display: block;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
    }

    .one, .two, .three
    {
      rotate: 180deg;
      
    }

    .two
    {
      margin-top: -20px;
    }

    .three
    {
      margin-top: -20px;
    }


    .calmText{    
      left: 70px;
      top: 15px;
      font-size: 21px;
    }
    
    .spinner__image {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0.5%;
      top: 0.5%;
    }


    .intro--disappear .mouseAnimation{
      width: 25px;
      height: 47px;
      border: 2.2px solid rgb(62, 214, 214);
      margin-bottom: 7em;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 10px;
        left: 53%;
        transform: translateX(-50%);
        background-color: rgb(62, 214, 214);
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
    }
  }

}

@media screen and (min-width: 993px) and (max-width: 1679px){
  .logo {
    font-size: 3.7rem;
    letter-spacing: 0.04rem;
    margin-top: 13px;
  }

  .intro--disappear .logo {
    top: 15px;
    transform: scale(0.8);
    transform-origin: center top;
    font-size: 3.4rem;
    letter-spacing: 0.02rem;
    width: 43rem;
  }

  .intro--disappear .intro__scroll{
   font-size: 1.6rem;
   margin-top: 0.4rem;
  }

  .intro--disappear .intro__scroll1{
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 6rem;
   }

  .spinner__image {
    width: 90px;
    height: 90px;
    
  }

  .outro {
    width: 800px;
    right: 260px;
  }

  .outro__text {
    font-size: 2.2rem;
    margin-bottom: 2rem;
  }

  .explore {
      padding: 0.7rem 1.5rem;
      font-size: 1.3rem;
      letter-spacing: 0.25rem;
      margin-top: 200px;
    }

    .mouse_scroll {
      width: 24px;
      height: 50px;
      margin-left: 120px;
    }

    .m_scroll_arrows
    {   
      border-bottom: 2px solid rgb(147, 250, 236);
      border-right: 2px solid rgb(147, 250, 236);
      margin: 0 0 21px 4px;
      width: 18px;
      height: 18px;
    }

    .mouse {
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }
    .wheel {
      height: 5px;
      width: 2px;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }


    .mouse-hide {
      display: block;
      height: 52px;
      width: 30px;
      border: 2px solid rgb(147, 250, 236);
      margin-top: -10px;
    }
    .wheel-hide {
      height: 6px;
      width: 3px;
      display: block;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
    }

    .one, .two, .three
    {
      rotate: 180deg;
      
    }

    .two
    {
      margin-top: -20px;
    }

    .three
    {
      margin-top: -20px;
    }


    .calmText{    
      left: 70px;
      top: 15px;
      font-size: 21px;
    }
    
    .spinner__image {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0.5%;
      top: 0.5%;
    }


    .intro--disappear .mouseAnimation{
      width: 25px;
      height: 47px;
      border: 2.2px solid rgb(62, 214, 214);
      margin-bottom: 12.5em;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 10px;
        left: 53%;
        transform: translateX(-50%);
        background-color: rgb(62, 214, 214);
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
    }
  }
}

@media screen and (min-width: 1680px){
  .logo {
    font-size: 3.7rem;
    letter-spacing: 0.05rem;
    margin-top: 13px;
  }

  .intro--disappear .logo {
    top: 15px;
    transform: scale(0.8);
    transform-origin: center top;
    font-size: 3.4rem;
    letter-spacing: 0.02rem;
    width: 41rem;
  }

  .intro--disappear .intro__scroll{
   font-size: 1.6rem;
   margin-top: 0.4rem;
  }

  .intro--disappear .intro__scroll1{
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 6rem;
   }

  .spinner__image {
    width: 90px;
    height: 90px;
    
  }

  .outro {
    width: 1950px;
    right: 660px;
  }

  .outro__text {
    font-size: 2.2rem;
    margin-bottom: 2rem;
  }

  .explore {
      padding: 0.7rem 1.5rem;
      font-size: 1.3rem;
      letter-spacing: 0.25rem;
      margin-top: 200px;
    }

    .mouse_scroll {
      width: 24px;
      height: 50px;
      margin-left: 125px;
    }

    .m_scroll_arrows
    {   
      border-bottom: 2px solid rgb(147, 250, 236);
      border-right: 2px solid rgb(147, 250, 236);
      margin: 0 0 23px 5.5px;
      width: 18px;
      height: 18px;
    }

    .mouse {
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }
    .wheel {
      height: 5px;
      width: 2px;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }


    .mouse-hide {
      display: block;
      height: 52px;
      width: 30px;
      border: 2px solid rgb(147, 250, 236);
      margin-top: -10px;
    }
    .wheel-hide {
      height: 6px;
      width: 3px;
      display: block;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
    }

    .one, .two, .three
    {
      rotate: 180deg;
      
    }

    .two
    {
      margin-top: -20px;
    }

    .three
    {
      margin-top: -20px;
    }


    .calmText{    
      left: 70px;
      top: 15px;
      font-size: 21px;
    }
    
    .spinner__image {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0.5%;
      top: 0.5%;
    }


    .intro--disappear .mouseAnimation{
      width: 25px;
      height: 47px;
      border: 2.2px solid rgb(62, 214, 214);
      margin-bottom: 13.5em;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 10px;
        left: 53%;
        transform: translateX(-50%);
        background-color: rgb(62, 214, 214);
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
    }
  }
}

@media screen and (max-width: 700px) and (orientation: landscape){
  .logo {
    font-size: 1.3rem;
    width: 17.9rem;
    letter-spacing: 0.05rem;
    margin-top: 10px;
  }

  .intro--disappear .logo {
    top: 15px;
    transform: scale(0.4);
    transform-origin: center top;
    font-size: 3rem;
    letter-spacing: 0.02rem;
    width: 39rem;
  }

  .intro--disappear .intro__scroll{
   font-size: 0.9rem;
   margin-top: 1.4rem;
  }

  .intro--disappear .intro__scroll1{
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 4.8rem;
   }

  .spinner__image {
    width: 90px;
    height: 90px;
    
  }

  .outro {
    right: 70px;
  }

  .outro__text {
    font-size: 1.1rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .explore {
      padding: 0.6rem 1.4rem;
      font-size: 0.9rem;
      letter-spacing: 0.25rem;
      margin-top: 180px;
    }

    .mouse_scroll {
      width: 24px;
      height: 50px;
      margin-left: 90px;
    }

    .m_scroll_arrows
    {   
      border-bottom: 2px solid rgb(147, 250, 236);
      border-right: 2px solid rgb(147, 250, 236);
      margin: 0 0 21px 4px;
      width: 16px;
      height: 16px;
    }

    .mouse {
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      display: none;

    }
    .wheel {
      height: 5px;
      width: 2px;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
      display: none;
    }


    .mouse-hide {
      display: block;
      height: 42px;
      width: 24px;
      border: 2px solid rgb(147, 250, 236);
      margin-top: -10px;
    }
    .wheel-hide {
      height: 5px;
      width: 2px;
      display: block;
      margin: 5px auto;
      height: 4px;
      width: 4px;
      border: 2px solid rgb(147, 250, 236);
    }

    .one, .two, .three
    {
      rotate: 180deg;
      
    }

    .two
    {
      margin-top: -20px;
    }

    .three
    {
      margin-top: -20px;
    }


    .calmText{    
      left: 70px;
      top: 15px;
      font-size: 21px;
    }
    
    .spinner__image {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0.5%;
      top: 0.5%;
    }


    .intro--disappear .mouseAnimation{
      width: 18px;
      height: 35px;
      border: 2.2px solid rgb(62, 214, 214);
      margin-bottom: -6em;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        left: 52.77%;
        transform: translateX(-50%);
        background-color: rgb(62, 214, 214);
        border-radius: 50%;
        opacity: 1;
        animation: wheelLandscape 1.5s infinite;
        -webkit-animation: wheelLandscape 1.5s infinite;
    }
  }
  

}




/* ANIMATIONS */

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
    filter: blur(9px);
  }

  100% {
    opacity: 1;
    filter: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes mouse-wheel{
  0% {
   opacity: 1;
   -webkit-transform: translateY(0);
   -ms-transform: translateY(0);
   transform: translateY(0);
 }

 100% {
   opacity: 0;
   -webkit-transform: translateY(6px);
   -ms-transform: translateY(6px);
   transform: translateY(6px);
 }
}

@-moz-keyframes mouse-wheel {
 0% { top: 1px; }
 25% { top: 2px; }
 50% { top: 3px;}
 75% { top: 2px;}
 100% { top: 1px;}
}

@-o-keyframes mouse-wheel {

  0% { top: 1px; }
 25% { top: 2px; }
 50% { top: 3px;}
 75% { top: 2px;}
 100% { top: 1px;}
}

@keyframes mouse-wheel {

  0% { top: 1px; }
 25% { top: 2px; }
 50% { top: 3px;}
 75% { top: 2px;}
 100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

 0%   { opacity: 0;}
 50%  { opacity: .5;}
 100% { opacity: 1;}
}

@-moz-keyframes mouse-scroll {

 0%   { opacity: 0; }
 50%  { opacity: .5; }
 100% { opacity: 1; }
}

@-o-keyframes mouse-scroll {

 0%   { opacity: 0; }
 50%  { opacity: .5; }
 100% { opacity: 1; }
}

@keyframes mouse-scroll {

 0%   { opacity: 0; }
 50%  { opacity: .5; }
 100% { opacity: 1; }
}


@keyframes wheelLandscape {
	to {
		opacity: 0;
		top: 25px;
	}
}

@-webkit-keyframes wheelLandscape {
	to {
		opacity: 0;
		top: 35px;
	}
}


@keyframes wheel {
	to {
		opacity: 0;
		top: 40px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}