@import url('https://fonts.googleapis.com/css?family=Cairo');

.titleWeb {
	font-family: "Cairo";
	text-align: center;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	letter-spacing: 2.5em;
	line-height:2;
	width:100%;
}

.text1Web {
	text-transform: uppercase;
	font-size: 120px;
	margin-bottom: 5%;
	animation: neon 1s ease infinite;
}
.text2Web {
	text-transform: uppercase;
	font-size: 120px;
	animation: neon 1s ease infinite;
	
}
.textWeb {
	color: #587ec7;
	font-size: 70px;
	margin-top: 5%;
	
}

@media only screen and (max-width: 500px) {

	.text1Web {
		font-size: 30px;
		letter-spacing: 0.3em;
		margin-left: 4%;
	}
	.text2Web {
		font-size: 30px;
		letter-spacing: 0.5em;
		margin-left: 5%;
	}
	.textWeb {
		font-size: 25px;
		margin-top: 10%;
		margin-left: 2%;
		letter-spacing: 0.1em;
	}
  }


  @media only screen and (min-width: 501px) and (max-width: 1200px) {

	.text1Web {
		font-size: 50px;
		letter-spacing: 0.3em;
		margin-left: 4%;
	}
	.text2Web {
		font-size: 50px;
		letter-spacing: 0.5em;
		margin-left: 5%;
	}
	.textWeb {
		font-size: 30px;
		margin-top: 10%;
		margin-left: 2%;
		letter-spacing: 0.1em;
	}
  }



  
  @keyframes neon {
    0%,
    100% {
        text-shadow: 0 0 10px #587ec7, 0 0 20px #587ec7, 0 0 20px #587ec7, 0 0 20px #587ec7, 0 0 2px #fed128, 2px 2px 2px #806914;
        color: #f5efcb;
    }
    50% {
        text-shadow: 0 0 2px #2a61c9, 0 0 5px #2a61c9, 0 0 5px #2a61c9, 0 0 5px #2a61c9, 0 0 2px #2a61c9, 4px 4px 2px #40340a;
        color: #7198e1;
    }
}
